import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["taxId", "title"]

  checkPaper() {
    const el = event.currentTarget

    const paper = document.querySelector(el.dataset['paper'])

    if (el.checked && !paper.checked) {
      paper.dispatchEvent(new Event('click')) // trigger it to hide email
      paper.checked = true
    }

    paper.disabled = !paper.disabled
  }

  lookupTax(event) {
    var tax_id = event.currentTarget.value
    if (tax_id.length == 8) {
      fetch(`/taxes/${event.currentTarget.value}`)
      .then(response => {
        if (!response.ok) {
          // make the promise be rejected if we didn't get a 2xx response
          throw new Error("Not 2xx response")
        } else {
          return response.json()     // go the desired response
        }
      }).catch(error => console.log("Error", error))
      .then(json => {
        if (json) this.titleTarget.value = json['title']
      })
    }
  }
}
