// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import consumer from '../channels/consumer'
import controller from '../../components/application_controller'

const application = Application.start()

import 'swiper/swiper-bundle.min.css'
import Carousel from "stimulus-carousel"
application.register("carousel", Carousel)

//import FlashController from 'stimulus-flash'
//application.register('flash', FlashController)

const context = require.context("controllers", true, /_controller\.js$/)
const context_components = require.context("../../components", true, /_controller.js$/)
application.load(
  definitionsFromContext(context)
  .concat(definitionsFromContext(context_components))
)

import StimulusReflex from 'stimulus_reflex'
StimulusReflex.initialize(application, { consumer, controller })
StimulusReflex.debug = process.env.RAILS_ENV === 'development'
