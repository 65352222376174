import { Controller } from "stimulus";

class AdvancedController extends Controller {
  static targets = ["result"];

  valueChanged() {
    this.targets.find("result").
      innerHTML = "Some input has changed its value";
  }
}

export default AdvancedController;
